import { useState, useEffect, useCallback, useMemo } from "react";
import { celularMask } from "../masks/celularMask";
import { cepMask } from "../masks/cepMask";
import instAxios from "../instanceAxios";
import xImage from "../images/x.svg";

//let timerCep; // para validar o cep

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const ref = urlParams.get('ref');
let is_depen = ref === 'd37156ac48810a85399020899487ea2e'



function Cadastro({
  data,
  autoContraste,
  tamanhos,
  tamanho,
  setPage,
  setShowTermos,
  setData,
  setShowProgress,
}) {
  const [nacionalidade, setNacionalidade] = useState("");
  const [cidades, setCidades] = useState([]);
  const [empregabilidade, setEmpregabilidade] = useState("");
  const [SituacaoForcaArmada, setSituacaoForcaArmada] = useState("");
  const [QualForcaArmada, setQualForcaArmada] = useState("");
  const [comoSoube, setComoSoube] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [sending, setSending] = useState(false);
  const [showAlert, setShowAlert] = useState("");
  const [comunidade, setComunidade] = useState("");
  const [form, setForm] = useState(() => ({
    id: data.usuarioId,
    email: data.email,
    senha: data.senha,
    Cpf: data.Cpf,
    name: "",
    emailPessoal: data.emailPessoal,
    NumCelular: "",
    UF: "",
    Cidade: "",
    //Endereco: "",
    //Numero: "",
    //Bairro: "",
    //Complemento: "",
    //CEP: "",
    NecessidadeEspecial: "",
    DataNascimento: "",
    Genero: "",
    raca: "",
    EstadoCivil: "",
    Nacionalidade: "",
    Escolaridade: "",
    Empregabilidade: "",
    comoSoube: "",
    termo: false,
    SituacaoForcaArmada: "",
    PostoForcaArmada: "",
    QualForcaArmada: "",
    ComandoForcaArmada: "",
  }));

  const rulesForm = useMemo(
    () => ({
      email: ["required"],
      senha: ["required", ["minLength", 8]],
      Cpf: ["required"],
      name: ["required", ["minLength", 3]],
      emailPessoal: ["required"],
      raca: ["required"],
      EstadoCivil: ["required"],
      Escolaridade: ["required"],
      Empregabilidade: ["required"],
      comoSoube: is_depen ? [] : ["required"],
      termo: ["required"],
      NumCelular: is_depen ? [] : ["required"],
      UF: ["required"],
      Cidade: ["required"],
      //Endereco: ["required"],
      //Numero: ["required"],
      //Bairro: ["required"],
      //CEP: ["required"],
      NecessidadeEspecial: ["required"],
      DataNascimento: ["required", "minMaxDate"],
      Genero: ["required"],
      Comunidade: ["required"],
      Nacionalidade: ["required"],
    }),
    []
  );

  const errorMessages = useMemo(
    () => ({
      email: {
        required: "Por favor digite um email.",
      },
      senha: {
        required: "Por favor digite uma senha.",
        minLength: `A senha deve ter no mínimo ${rulesForm.senha[1][1]} caracteres.`,
      },
      name: {
        required: "Por favor digite um nome.",
        minLength: `O nome deve ter no mínimo ${rulesForm.name[1][1]} letras.`,
      },
      emailPessoal: {
        required: "Por favor digite seu email pessoal.",
      },
      raca: {
        required: "Por favor escolha sua raça.",
      },
      EstadoCivil: {
        required: "Por favor escolha seu estado civil.",
      },
      Escolaridade: {
        required: "Por favor escolha sua escolaridade.",
      },
      Empregabilidade: {
        required: "Por favor escolha sua empregabilidade.",
      },
      comoSoube: {
        required: "Por favor escolha como soube da Escola do Trabalhado.",
      },
      termo: {
        required: "Você deve concordar com os termos para cadastrar.",
      },
      Cpf: {
        required: "Por favor digite seu CPF.",
      },
      NumCelular: {
        required: "Por favor digite o número do seu celular.",
      },
      UF: {
        required: "Por favor escolha seu estado.",
      },
      Cidade: {
        required: "Por favor escolha sua cidade.",
      },
      /*Endereco: {
        required: "Por favor digite seu endereço.",
      },
      Numero: {
        required: "Por favor digite seu endereço.",
      },
      Bairro: {
        required: "Por favor digite seu bairro.",
      },
      CEP: {
        required: "Por favor digite seu CEP.",
      },*/
      NecessidadeEspecial: {
        required: "Por favor escolha uma opção.",
      },
      DataNascimento: {
        required: "Por favor digite sua data de nascimento.",
        minMaxDate: "Por favor digite uma data de nascimento válida ou escolha uma opção no calendário.",
      },
      Genero: {
        required: "Por favor escolha uma opção.",
      },
      Comunidade: {
        required: "Por favor escolha uma opção.",
      },
      Nacionalidade: {
        required: "Por favor selecione sua nacionalidade.",
      },
    }),
    [rulesForm.senha, rulesForm.name]
  );

  const handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    let value = target.type === "checkbox" ? target.checked : target.value;

    if (name === "NumCelular") {
      value = celularMask(value);
    } else if (name === "CEP") {
      value = cepMask(value);
    } else if (name === "Empregabilidade") {
      setEmpregabilidade(value);
    } else if (name === "SituacaoForcaArmada") {
      setSituacaoForcaArmada(value);
    } else if (name === "QualForcaArmada") {
      setQualForcaArmada(value);
    } else if (name === "UF") {
      setCidadesApi(value);
    }

    setForm((form) => ({
      ...form,
      [name]: value,
    }));
  };

  const setCidadesApi = async (uf) => {
    const response = await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/municipios?orderBy=nome`)
    const records = await response.json()
    //console.log('records', records);
    var result = records.map((value) => {
      let city = {
        "id": value.id,
        "nome": value.nome
      }
      return city
    });
    setCidades(result)
  };

  const handleNascionalidade = (event) => {
    const value = event.target.value;
    if (value === "Brasileira") {
      setForm((f) => ({ ...f, Nacionalidade: value }));
    } else {
      setForm((f) => ({ ...f, Nacionalidade: "" }));
    }
    setNacionalidade(value);

    if(is_depen) {
      setComoSoube('DEPEN');
      setForm((f) => ({ ...f, comoSoube: "DEPEN" }));
    }
  };

  const handleComoSoube = (event) => {
    const value = event.target.value;
    if (value !== "Outro") {
      setForm((f) => ({ ...f, comoSoube: value }));
    } else {
      setForm((f) => ({ ...f, comoSoube: "" }));
    }
    setComoSoube(value);
  };

  const handleComunidade = (event) => {
    const value = event.target.value;
    setForm((f) => ({ ...f, Comunidade: value }));
    // let newGenero = form.Genero + " " + value;
    // if (form.Genero !== "") {
    //   setForm((f) => ({ ...f, Genero: newGenero }));
    //   console.log("form", form)
    // } 
    setComunidade(value);
  };

  const validateMinLength = useCallback(
    (field, length) => {
      if (field && length && form) {
        if (form[field] && form[field].length < length) {
          return false;
        }
      }
      return true;
    },
    [form]
  );

  const validateMinMaxDate = useCallback(
    (field) => {
      if (field && form) {
        let dataNascimento = new Date(form[field]);
        let dataMax = new Date('2011-12-31');
        let dataMin = new Date('1930-01-01');
        if (form[field] && (dataNascimento < dataMin || dataNascimento > dataMax)) {
          return false;
        }
      }
      return true;
    },
    [form]
  );

  const validateField = useCallback(
    (field) => {
      if (submitted && rulesForm && rulesForm[field]) {
        for (const rule of rulesForm[field]) {
          if (rule === "required") {
            if (!form[field]) {
              return { valid: false, msg: errorMessages[field].required };
            }
          } else if(rule === "minMaxDate") {
            if (!validateMinMaxDate(field)) {
              return { valid: false, msg: errorMessages[field].minMaxDate };
            }
            
          } else if (typeof rule === "object") {
            if (rule[0] === "minLength" && !validateMinLength(field, rule[1])) {
              return { valid: false, msg: errorMessages[field].minLength };
            }
          }
        }
      }
      return { valid: true, msg: "" };
    },
    [submitted, rulesForm, errorMessages, validateMinLength, validateMinMaxDate, form]
  );

  const isValid = useCallback(
    (field) => {
      return validateField(field).valid;
    },
    [validateField]
  );

  const getErrorMessage = (field) => {
    return validateField(field).msg;
  };

  const validateForm = useCallback(() => {
    const validFields = [];
    for (const field of Object.keys(form)) {
      validFields.push(isValid(field));
    }

    return !validFields.some((v) => v === false);
  }, [form, isValid]);

  {/*useEffect(() => {
    const cep = (form.CEP || "").replace(/\D/g, "");
    if (cep && cep.length === 8) {
      if (timerCep) {
        clearTimeout(timerCep);
        timerCep = null;
      }
      timerCep = setTimeout(() => {
        setShowProgress(true);
        instAxios
          .get(`Trabalhador/adress/validate?cep=${cep}`)
          .then((response) => {
            // console.log('resposta positiva',response);
            if (response.data.success === true) {
              const { estado, cidade, bairro, logradouro } = response.data;
              setForm((form) => ({
                ...form,
                UF: estado,
                Cidade: cidade,
                Endereco: logradouro,
                Bairro: bairro,
              }));
            }
          })
          .catch((error) => {
            console.log("deu erro", error);
          })
          .finally(() => {
            setShowProgress(false);
          });
      }, 1000);
    }
  }, [form.CEP, setForm, setShowProgress]);*/}

  useEffect(() => {
    if (submitted) handleSubmit(null);
  }, [submitted]);

  const handleSubmit = useCallback(
    (event) => {
      if (event) event.preventDefault();
      if (!submitted) {
        setSubmitted(true);
      } else {
        if (validateForm() && !sending) {
          if (
            form.Empregabilidade !==
            "Militar/Servidor Civil/Familiar das Forças Armadas"
          ) {
            form.SituacaoForcaArmada = null;
            form.PostoForcaArmada = null;
            form.QualForcaArmada = null;
            form.ComandoForcaArmada = null;
          }
          let newGenero = form.Genero + " " + comunidade;
          form.Genero = newGenero;
          // if(form.Comunidade) {
          //   delete form.Comunidade;
          // }
       

          setSending(true);
          setShowProgress(true);
          const { id } = form;
          instAxios
            .put(`Trabalhador/user/${id}`, form)
            .then((response) => {
              // console.log('resposta positiva',response);
              if (response.data.success === true) {
                setData((d) => ({ ...d, ...form }));
                setTimeout(() => {
                  setPage("fim");
                }, 500);
              } else {
                setShowAlert(response.data.message);
              }
            })
            .catch((error) => {
              console.log("deu erro", error);
            })
            .finally(() => {
              setShowProgress(false);
              setSending(false);
            });
        }
      }
    },
    [form, sending, setData, setShowProgress, submitted, validateForm, setPage]
  );

  return (
    <div className="hold-form cadastro">
      <section className="formulario">
        <h2>Para acessar a plataforma complete seu cadastro</h2>
        <form className="g-3">
          <section className="row">
            <div className="mb-3 col-md-4">
              <label>Nome completo*</label>
              <input
                type="text"
                className={`form-control ${
                  !isValid("name") ? "is-invalid" : ""
                }`}
                name="name"
                value={form.name}
                onChange={handleInputChange}
                disabled={sending}
              />
              <div className="invalid-feedback">{getErrorMessage("name")}</div>
            </div>
            <div className="mb-3 col-md-4" style={is_depen ? {display: 'none'} : {display: 'block'}}>
              <label>CPF*</label>
              <input
                type="tel"
                className={`form-control ${
                  !isValid("Cpf") ? "is-invalid" : ""
                }`}
                name="Cpf"
                value={form.Cpf}
                onChange={handleInputChange}
                disabled
              />
              <div className="invalid-feedback">{getErrorMessage("Cpf")}</div>
            </div>
            <div className="mb-3 col-md-4">
              <label>Possui alguma deficiência, altas habilidades ou superdotação?*</label>
              <select
                className={`form-select form-control ${
                  !isValid("NecessidadeEspecial") ? "is-invalid" : ""
                }`}
                name="NecessidadeEspecial"
                value={form.NecessidadeEspecial}
                onChange={handleInputChange}
                disabled={sending}
              >
                <option value="">Possui alguma deficiência, altas habilidades ou superdotação?</option>
                <option value="nao">NÃO</option>
                <option value="Deficiência física">Deficiência física</option>
                <option value="Deficiência visual">Deficiência visual</option>
                <option value="Deficiência auditiva">
                  Deficiência auditiva
                </option>
                <option value="Deficiência intelectual">
                  Deficiência intelectual
                </option>
                <option value="Deficiência psicossocial">
                  Deficiência psicossocial
                </option>
                <option value="Deficiência múltipla">
                  Deficiência múltipla
                </option>
                <option value="Autismo">
                  Autismo
                </option>
                <option value="Altas habilidades">
                  Altas habilidades
                </option>
                <option value="Superdotação">
                  Superdotação
                </option>
              </select>
              <div className="invalid-feedback">
                {getErrorMessage("NecessidadeEspecial")}
              </div>
            </div>
          </section>
          <section className="row">
            <div className="mb-3 col-md-4">
              <label>Data de Nascimento*</label>
              <input
                type="date"
                min="1930-01-01" 
                max="2010-12-31"
                className={`form-control ${
                  !isValid("DataNascimento") ? "is-invalid" : ""
                }`}
                name="DataNascimento"
                value={form.DataNascimento}
                onChange={handleInputChange}
                disabled={sending}
              />
              <div className="invalid-feedback">
                {getErrorMessage("DataNascimento")}
              </div>
            </div>
            <div className="mb-3 col-md-4">
              <label>Nacionalidade*</label>
              <select
                className={`form-select form-control ${
                  !isValid("Nacionalidade") ? "is-invalid" : ""
                }`}
                name="selectNacionalidade"
                value={nacionalidade}
                onChange={handleNascionalidade}
                disabled={sending}
              >
                <option value="">Nacionalidade</option>
                <option value="Brasileira">Brasileira</option>
                <option value="Outro">Outro</option>
              </select>
              <div className="invalid-feedback">
                {nacionalidade !== "Outro" && getErrorMessage("Nacionalidade")}
              </div>
            </div>
            {nacionalidade === "Outro" && (
              <div className="mb-3 col-md-4">
                <label>Qual?*</label>
                <input
                  type="text"
                  className={`form-control ${
                    !isValid("Nacionalidade") ? "is-invalid" : ""
                  }`}
                  name="Nacionalidade"
                  value={form.Nacionalidade}
                  onChange={handleInputChange}
                  disabled={sending}
                />
                <div className="invalid-feedback">
                  {getErrorMessage("Nacionalidade")}
                </div>
              </div>
            )}
            <div className="mb-3 col-md-4">
              <label>Estado civil*</label>
              <select
                className={`form-select form-control ${
                  !isValid("EstadoCivil") ? "is-invalid" : ""
                }`}
                name="EstadoCivil"
                value={form.EstadoCivil}
                onChange={handleInputChange}
                disabled={sending}
              >
                <option value="">Estado civil*</option>
                <option value="Solteiro">Solteiro (a)</option>
                <option value="Casado">Casado (a)</option>
                <option value="Divorciado">Divorciado (a)</option>
                <option value="Viúvo">Viúvo (a)</option>
                <option value="Outro">Outro (a)</option>
              </select>
              <div className="invalid-feedback">
                {getErrorMessage("EstadoCivil")}
              </div>
            </div>
          </section>
          <section className="row">
            <div className="mb-3 col-md-2">
              <label>UF*</label>
              <select
                className={`form-select form-control ${
                  !isValid("UF") ? "is-invalid" : ""
                }`}
                name="UF"
                value={form.UF}
                onChange={handleInputChange}
                disabled={sending}
              >
                <option value="">Estado</option>
                <option value="AC">Acre</option>
                <option value="AL">Alagoas</option>
                <option value="AP">Amapá</option>
                <option value="AM">Amazonas</option>
                <option value="BA">Bahia</option>
                <option value="CE">Ceará</option>
                <option value="DF">Distrito Federal</option>
                <option value="ES">Espírito Santo</option>
                <option value="GO">Goiás</option>
                <option value="MA">Maranhão</option>
                <option value="MT">Mato Grosso</option>
                <option value="MS">Mato Grosso do Sul</option>
                <option value="MG">Minas Gerais</option>
                <option value="PA">Pará</option>
                <option value="PB">Paraíba</option>
                <option value="PR">Paraná</option>
                <option value="PE">Pernambuco</option>
                <option value="PI">Piauí</option>
                <option value="RJ">Rio de Janeiro</option>
                <option value="RN">Rio Grande do Norte</option>
                <option value="RS">Rio Grande do Sul</option>
                <option value="RO">Rondônia</option>
                <option value="RR">Roraima</option>
                <option value="SC">Santa Catarina</option>
                <option value="SP">São Paulo</option>
                <option value="SE">Sergipe</option>
                <option value="TO">Tocantins</option>
              </select>
              <div className="invalid-feedback">{getErrorMessage("UF")}</div>
            </div>
            <div className="mb-3 col-md-2">
              <label>Cidade*</label>
              <select
                className={`form-select form-control ${
                  !isValid("Cidade") ? "is-invalid" : ""
                }`}
                name="Cidade"
                value={form.Cidade}
                onChange={handleInputChange}
                disabled={sending}
              >
                <option value="">Selecione o Estado</option>
                {
                  cidades.map( (cidade) => 
                    <option key={cidade.nome} value={cidade.nome}>{cidade.nome}</option> )
                }
              </select>
              {/* <input
                type="text"
                className={`form-control ${
                  !isValid("Cidade") ? "is-invalid" : ""
                }`}
                name="Cidade"
                value={form.Cidade}
                onChange={handleInputChange}
                disabled={sending}
              /> */}
              <div className="invalid-feedback">
                {getErrorMessage("Cidade")}
              </div>
            </div>
            <div className="mb-3 col-md-4">
              <label>Como você se reconhece? (identidade de gênero)*</label>
              <select
                className={`form-select form-control ${
                  !isValid("Genero") ? "is-invalid" : ""
                }`}
                name="Genero"
                value={form.Genero}
                onChange={handleInputChange}
                disabled={sending}
              >
                <option value="">Como você se reconhece? (identidade de gênero)</option>
                <option value="Mulher">Mulher Cisgênera (que se identifica com o sexo que lhe foi designado ao nascer)</option>
                <option value="Homem">Homem Cisgênero (que se identifica com o sexo que lhe foi designado ao nascer)</option>
                <option value="Mulher Trans">Mulher Transexual/Transgênera (possui outra identidade de gênero,   diferente da que lhe foi designada ao nascer)</option>
                <option value="Homem Trans">Homem Transexual/Transgênero (possui outra identidade de gênero,   diferente da que lhe foi designada ao nascer)</option>
                <option value="Não binário">Não-Binário (não definem sua identidade dentro do sistema binário homem-mulher)</option>
                <option value="Outro">Outro/a</option>
                <option value="Prefiro não me classificar">
                  Prefiro não me classificar
                </option>
                <option value="Prefiro não responder">
                  Prefiro não responder
                </option>
              </select>
              <div className="invalid-feedback">
                {getErrorMessage("Genero")}
              </div>
            </div>
            <div className="mb-3 col-md-4">
              <label>Você se considera uma pessoa LGBTQIA+?*</label>
              <select
                className={`form-select form-control ${
                  !isValid("Comunidade") ? "is-invalid" : ""
                }`}
                name="Comunidade"
                value={form.Comunidade}
                onChange={handleComunidade}
                disabled={sending}
              >
                <option value="">Você se considera uma pessoa LGBTQIA+?</option>
                <option value="(Sim)">Sim</option>
                <option value="(Não)">Não</option>
                <option value="(prefiro-nao-responder)">
                  Prefiro não responder
                </option>
              </select>
              <div className="invalid-feedback">
                {getErrorMessage("Comunidade")}
              </div>
            </div>
            <div className="mb-3 col-md-4">
              <label>Com qual raça/cor que você se identifica*</label>
              <select
                className={`form-select form-control ${
                  !isValid("raca") ? "is-invalid" : ""
                }`}
                name="raca"
                value={form.raca}
                onChange={handleInputChange}
                disabled={sending}
              >
                <option value="">Com qual raça/cor que você se identifica*</option>
                <option value="Amarelo">Amarela (descendente de asiáticos)</option>
                <option value="Branco">Branca</option>
                <option value="Pardo">Parda</option>
                <option value="Preto">Preta não quilombola</option>
                <option value="Preta quilombola">Preta quilombola</option>
                <option value="Indígena aldeado">Indígena aldeado</option>
                <option value="Indígena">Indígena não aldeado</option>
                <option value="Prefiro não declarar minha cor ou raça">Prefiro não declarar minha cor ou raça</option>
              </select>
              <div className="invalid-feedback">{getErrorMessage("raca")}</div>
            </div>
          {/* </section> */}
          {/*<section className="row">
            <div className="mb-3 col-md-4">
              <label>Endereço*</label>
              <input
                type="text"
                className={`form-control ${
                  !isValid("Endereco") ? "is-invalid" : ""
                }`}
                name="Endereco"
                value={form.Endereco}
                onChange={handleInputChange}
                disabled={sending}
              />
              <div className="invalid-feedback">
                {getErrorMessage("Endereco")}
              </div>
            </div>
            <div className="mb-3 col-md-2">
              <label>Número*</label>
              <input
                type="number"
                className={`form-control ${
                  !isValid("Numero") ? "is-invalid" : ""
                }`}
                name="Numero"
                value={form.Numero}
                onChange={handleInputChange}
                disabled={sending}
              />
              <div className="invalid-feedback">
                {getErrorMessage("Numero")}
              </div>
            </div>
            <div className="mb-3 col-md-2">
              <label>Complemento</label>
              <input
                type="text"
                className="form-control"
                name="Complemento"
                value={form.Complemento}
                onChange={handleInputChange}
                disabled={sending}
              />
            </div>
            <div className="mb-3 col-md-4">
              <label>Bairro*</label>
              <input
                type="text"
                className={`form-control ${
                  !isValid("Bairro") ? "is-invalid" : ""
                }`}
                name="Bairro"
                value={form.Bairro}
                onChange={handleInputChange}
                disabled={sending}
              />
              <div className="invalid-feedback">
                {getErrorMessage("Bairro")}
              </div>
            </div>
              </section>*/}

          {/* <section className="row"> */}
            <div className="mb-3 col-md-4">
              <label>E-mail*</label>
              <input
                type="email"
                className={`form-control ${
                  !isValid("email") ? "is-invalid" : ""
                }`}
                name="email"
                value={form.email}
                onChange={handleInputChange}
                disabled
              />
              <div className="invalid-feedback">{getErrorMessage("email")}</div>
            </div>
            <div className="mb-3 col-md-4">
              <label>E-mail pessoal*</label>
              <input
                type="email"
                className={`form-control ${
                  !isValid("emailPessoal") ? "is-invalid" : ""
                }`}
                name="emailPessoal"
                value={form.emailPessoal}
                onChange={handleInputChange}
                disabled={sending}
              />
              <div className="invalid-feedback">
                {getErrorMessage("emailPessoal")}
              </div>
            </div>
          </section>

          <section className="row">
            <div className="form-floating mb-3 col-md-4">
              <label>Senha*</label>
              <input
                type="password"
                className={`form-control ${
                  !isValid("senha") ? "is-invalid" : ""
                }`}
                name="senha"
                value={form.senha}
                onChange={handleInputChange}
                disabled={sending}
              />
              <div className="invalid-feedback">{getErrorMessage("senha")}</div>
            </div>
            <div className="mb-3 col-md-4" style={is_depen ? {display: 'none'} : {display: 'block'}}>
              <label>Número de Celular*</label>
              <input
                type="tel"
                className={`form-control ${
                  !isValid("NumCelular") ? "is-invalid" : ""
                }`}
                name="NumCelular"
                value={form.NumCelular}
                onChange={handleInputChange}
                disabled={sending}
              />
              <div className="invalid-feedback">
                {getErrorMessage("NumCelular")}
              </div>
            </div>
            <div className="mb-3 col-md-4">
              <label>Escolaridade*</label>
              <select
                className={`form-select form-control ${
                  !isValid("Escolaridade") ? "is-invalid" : ""
                }`}
                name="Escolaridade"
                value={form.Escolaridade}
                onChange={handleInputChange}
                disabled={sending}
              >
                <option value="">Escolaridade*</option>
                <option value="Ensino Fundamental Incompleto">
                  Ensino Fundamental Incompleto
                </option>
                <option value="Ensino Fundamental Completo">
                  Ensino Fundamental Completo
                </option>
                <option value="Ensino Médio Incompleto">
                  Ensino Médio Incompleto
                </option>
                <option value="Ensino Médio Completo">
                  Ensino Médio Completo
                </option>
                <option value="Formação Superior Incompleta">
                  Formação Superior Incompleta
                </option>
                <option value="Formação Superior Completa">
                  Formação Superior Completa
                </option>
                <option value="Pós-graduação no nível Especialização Incompleta">
                  Pós-graduação no nível Especialização Incompleta
                </option>
                <option value="Pós-graduação no nível Especialização Completa">
                  Pós-graduação no nível Especialização Completa
                </option>
                <option value="Mestrado Incompleto">Mestrado Incompleto</option>
                <option value="Mestrado Completo">Mestrado Completo</option>
                <option value="Doutorado Incompleto">
                  Doutorado Incompleto
                </option>
                <option value="Doutorado Completo">Doutorado Completo</option>
              </select>
              <div className="invalid-feedback">
                {getErrorMessage("Escolaridade")}
              </div>
            </div>
          </section>

          <section className="row">
            <div className="mb-3 col-md-6">
              <label>Empregabilidade?*</label>
              <select
                className={`form-select form-control ${
                  !isValid("Empregabilidade") ? "is-invalid" : ""
                }`}
                name="Empregabilidade"
                value={form.Empregabilidade}
                onChange={handleInputChange}
                disabled={sending}
              >
                <option value="">Empregabilidade*</option>
                <option value="Emprego Formal">Emprego Formal</option>
                <option value="Emprego Informal">Emprego Informal</option>
                <option value="Empreendedor">Empreendedor - Com registro MEI</option>
                <option value="Empreendedor - Sem registro MEI">Empreendedor - Sem registro MEI</option>
                <option value="Desempregado">Desempregado</option>
                <option value="Aposentado">Aposentado</option>
                <option value="Estudante">Estudante</option>
                <option value="Professor">Professor</option>
                <option value="Militar/Servidor Civil/Familiar das Forças Armadas">
                  Militar/Servidor Civil/Familiar das Forças Armadas
                </option>
                <option value="Servidor Público do Sistema Penitenciário">Servidor Público do Sistema Penitenciário</option>
                <option value="Outro">Outro</option>
              </select>
              <div className="invalid-feedback">
                {getErrorMessage("Empregabilidade")}
              </div>
            </div>
            {empregabilidade ===
              "Militar/Servidor Civil/Familiar das Forças Armadas" && (
              <div className="mb-2 col-md-2">
                <label>Situacao*</label>
                <select
                  className={`form-select form-control ${
                    !isValid("SituacaoForcaArmada") ? "is-invalid" : ""
                  }`}
                  name="SituacaoForcaArmada"
                  value={form.SituacaoForcaArmada}
                  onChange={handleInputChange}
                  disabled={sending}
                >
                  <option value="">Selecione...</option>
                  <option value="Militar">Militar</option>
                  <option value="Militar da Reserva ou Reformado">
                    Militar da Reserva ou Reformado
                  </option>
                  <option value="Servidor Civil">Servidor Civil</option>
                  <option value="Familiar">Familiar</option>
                </select>
                <div className="invalid-feedback">
                  {getErrorMessage("SituacaoForcaArmada")}
                </div>
              </div>
            )}
            {empregabilidade ===
              "Militar/Servidor Civil/Familiar das Forças Armadas" &&
            (SituacaoForcaArmada === "Militar" ||
              SituacaoForcaArmada === "Militar da Reserva ou Reformado") ? (
              <div className="mb-4 col-md-4">
                <label>Escolha do Posto ou Graduação*</label>
                <select
                  className={`form-select form-control ${
                    !isValid("PostoForcaArmada") ? "is-invalid" : ""
                  }`}
                  name="PostoForcaArmada"
                  value={form.PostoForcaArmada}
                  onChange={handleInputChange}
                  disabled={sending}
                >
                  <option value="">Selecione...</option>
                  <option value="Marinha - Almirante de Esquadra">
                    Marinha - Almirante de Esquadra
                  </option>
                  <option value="Marinha - Vice-Almirante">
                    Marinha - Vice-Almirante
                  </option>
                  <option value="Marinha - Contra-Almirante">
                    Marinha - Contra-Almirante
                  </option>
                  <option value="Marinha - Capitão de Fragata">
                    Marinha - Capitão de Fragata
                  </option>
                  <option value="Marinha - Capitão de Corveta">
                    Marinha - Capitão de Corveta
                  </option>
                  <option value="Marinha - Capitão-Tenente">
                    Marinha - Capitão-Tenente
                  </option>
                  <option value="Marinha - 1º Tenente">
                    Marinha - 1º Tenente
                  </option>
                  <option value="Marinha - 2º Tenente">
                    Marinha - 2º Tenente
                  </option>
                  <option value="Marinha - Guarda Marinha">
                    Marinha - Guarda Marinha
                  </option>
                  <option value="Marinha - Suboficial">
                    Marinha - Suboficial
                  </option>
                  <option value="Marinha - 1º Sargento">
                    Marinha - 1º Sargento
                  </option>
                  <option value="Marinha - 2º Sargento">
                    Marinha - 2º Sargento
                  </option>
                  <option value="Marinha - 3º Sargento">
                    Marinha - 3º Sargento
                  </option>
                  <option value="Marinha - Cabo">Marinha - Cabo</option>
                  <option value="Marinha - Marinho e Soldado Fuzileiro Naval">
                    Marinha - Marinho e Soldado Fuzileiro Naval
                  </option>
                  <option value="Exército - Genral do Exército">
                    Exército - Genral do Exército
                  </option>
                  <option value="Exército - General da Divisão">
                    Exército - General da Divisão
                  </option>
                  <option value="Exército - General da Brigada">
                    Exército - General da Brigada
                  </option>
                  <option value="Exército - Coronel">Exército - Coronel</option>
                  <option value="Exército - Tenente-Coronel">
                    Exército - Tenente-Coronel
                  </option>
                  <option value="Exército - Major">Exército - Major</option>
                  <option value="Exército - Capitão">Exército - Capitão</option>
                  <option value="Exército - 1º Tenente">
                    Exército - 1º Tenente
                  </option>
                  <option value="Exército - 2º Tenente">
                    Exército - 2º Tenente
                  </option>
                  <option value="Exército - Aspirante a Oficial">
                    Exército - Aspirante a Oficial
                  </option>
                  <option value="Exército - Subtenente">
                    Exército - Subtenente
                  </option>
                  <option value="Exército - 1º Sargento">
                    Exército - 1º Sargento
                  </option>
                  <option value="Exército - 2º Sargento">
                    Exército - 2º Sargento
                  </option>
                  <option value="Exército - 3º Sargento">
                    Exército - 3º Sargento
                  </option>
                  <option value="Exército - Cabo e Taifero-mor">
                    Exército - Cabo e Taifero-mor
                  </option>
                  <option value="Exército - Soldado (EP) e Taifeiro 1ª Classe">
                    Exército - Soldado (EP) e Taifeiro 1ª Classe
                  </option>
                  <option value="Aeronáutica - Tenente-Brigadeiro">
                    Aeronáutica - Tenente-Brigadeiro
                  </option>
                  <option value="Aeronáutica - Major-Brigadeiro">
                    Aeronáutica - Major-Brigadeiro
                  </option>
                  <option value="Aeronáutica - Brigadeiro">
                    Aeronáutica - Brigadeiro
                  </option>
                  <option value="Aeronáutica - Coronel">
                    Aeronáutica - Coronel
                  </option>
                  <option value="Aeronáutica - Tenente-Coronel">
                    Aeronáutica - Tenente-Coronel
                  </option>
                  <option value="Aeronáutica - Major">
                    Aeronáutica - Major
                  </option>
                  <option value="Aeronáutica - Capitão">
                    Aeronáutica - Capitão
                  </option>
                  <option value="Aeronáutica - 1º Tenente">
                    Aeronáutica - 1º Tenente
                  </option>
                  <option value="Aeronáutica - 2º Tenente">
                    Aeronáutica - 2º Tenente
                  </option>
                  <option value="Aeronáutica - Aspirante a Oficial">
                    Aeronáutica - Aspirante a Oficial
                  </option>
                  <option value="Aeronáutica - Suboficial">
                    Aeronáutica - Suboficial
                  </option>
                  <option value="Aeronáutica - 1º Sargento">
                    Aeronáutica - 1º Sargento
                  </option>
                  <option value="Aeronáutica - 2º Sargento">
                    Aeronáutica - 2º Sargento
                  </option>
                  <option value="Aeronáutica - 3º Sargento">
                    Aeronáutica - 3º Sargento
                  </option>
                  <option value="Aeronáutica - Cabo e Taifero-mor">
                    Aeronáutica - Cabo e Taifero-mor
                  </option>
                  <option value="Aeronáutica - Soldado  1ª Classe e Taifeiro 1ª Classe">
                    Aeronáutica - Soldado 1ª Classe e Taifeiro 1ª Classe
                  </option>
                </select>
                <div className="invalid-feedback">
                  {getErrorMessage("PostoForcaArmada")}
                </div>
              </div>
            ) : (
              <></>
            )}
            {empregabilidade ===
              "Militar/Servidor Civil/Familiar das Forças Armadas" && (
              <div className="mb-3 col-md-3">
                <label>De qual Força Armada*</label>
                <select
                  className={`form-select form-control ${
                    !isValid("QualForcaArmada") ? "is-invalid" : ""
                  }`}
                  name="QualForcaArmada"
                  value={form.QualForcaArmada}
                  onChange={handleInputChange}
                  disabled={sending}
                >
                  <option value="">Selecione...</option>
                  <option value="Ministério da Defesa">
                    Ministério da Defesa
                  </option>
                  <option value="Exército Brasileiro">
                    Exército Brasileiro
                  </option>
                  <option value="Marinha do Brasil">Marinha do Brasil</option>
                  <option value="Força Aérea Brasileira">
                    Força Aérea Brasileira
                  </option>
                </select>
                <div className="invalid-feedback">
                  {getErrorMessage("QualForcaArmada")}
                </div>
              </div>
            )}
            {empregabilidade ===
              "Militar/Servidor Civil/Familiar das Forças Armadas" && (
              <div className="mb-3 col-md-4">
                <label>
                  Em qual Comando Você ou o Militar Titular esta situado*
                </label>
                <select
                  className={`form-select form-control ${
                    !isValid("ComandoForcaArmada") ? "is-invalid" : ""
                  }`}
                  name="ComandoForcaArmada"
                  value={form.ComandoForcaArmada}
                  onChange={handleInputChange}
                  disabled={sending}
                >
                  <option value="">Selecione...</option>
                  <option value="Marinha - 1º Distrito Naval">
                    Marinha - 1º Distrito Naval
                  </option>
                  <option value="Marinha - 2º Distrito Naval">
                    Marinha - 2º Distrito Naval
                  </option>
                  <option value="Marinha - 3º Distrito Naval">
                    Marinha - 3º Distrito Naval
                  </option>
                  <option value="Marinha - 4º Distrito Naval">
                    Marinha - 4º Distrito Naval
                  </option>
                  <option value="Marinha - 5º Distrito Naval">
                    Marinha - 5º Distrito Naval
                  </option>
                  <option value="Marinha - 6º Distrito Naval">
                    Marinha - 6º Distrito Naval
                  </option>
                  <option value="Marinha - 7º Distrito Naval">
                    Marinha - 7º Distrito Naval
                  </option>
                  <option value="Marinha - 8º Distrito Naval">
                    Marinha - 8º Distrito Naval
                  </option>
                  <option value="Marinha - 9º Distrito Naval">
                    Marinha - 9º Distrito Naval
                  </option>
                  <option value="Exército - CMA">Exército - CMA</option>
                  <option value="Exército - CMN">Exército - CMN</option>
                  <option value="Exército - CMNE">Exército - CMNE</option>
                  <option value="Exército - CMO">Exército - CMO</option>
                  <option value="Exército - CMP">Exército - CMP</option>
                  <option value="Exército - CMSE">Exército - CMSE</option>
                  <option value="Exército - CML">Exército - CML</option>
                  <option value="Exército - CMS">Exército - CMS</option>
                  <option value="Aeronáutica - COMAR I">
                    Aeronáutica - COMAR I
                  </option>
                  <option value="Aeronáutica - COMAR II">
                    Aeronáutica - COMAR II
                  </option>
                  <option value="Aeronáutica - COMAR III">
                    Aeronáutica - COMAR III
                  </option>
                  <option value="Aeronáutica - COMAR IV">
                    Aeronáutica - COMAR IV
                  </option>
                  <option value="Aeronáutica - COMAR V">
                    Aeronáutica - COMAR V
                  </option>
                  <option value="Aeronáutica - COMAR VI">
                    Aeronáutica - COMAR VI
                  </option>
                  <option value="Aeronáutica - COMAR VII">
                    Aeronáutica - COMAR VII
                  </option>
                </select>
                <div className="invalid-feedback">
                  {getErrorMessage("ComandoForcaArmada")}
                </div>
              </div>
            )}
            <div className="mb-3 col-md-6">
            <label style={is_depen ? {display: 'none'} : {display: 'block'}}>Como soube da Escola do Trabalhador 4.0?*</label>
              {is_depen ? (
              <input type={'hidden'} value={'DEPEN'} name={"selectComoSoube"} readOnly={true}/>
              ) :
            (<select
                className={`form-select form-control ${
                  !isValid("comoSoube") ? "is-invalid" : ""
                }`}
                name="selectComoSoube"
                value={comoSoube}
                onChange={handleComoSoube}
                disabled={sending}
              >
                <option value="">
                  Como soube da Escola do Trabalhador 4.0?*
                </option>
                <option value="Aplicativo da Carteira de Trabalho Digital">Aplicativo da Carteira de Trabalho Digital</option>
                <option value="Site do Programa">Site do Programa</option>
                <option value="Ministério do Trabalho e Previdência">
                  Ministério do Trabalho e Previdência
                </option>
                <option value="Redes Sociais">Redes Sociais</option>
                <option value="Conectar Educação (Governo da Bahia)">Conectar Educação (Governo da Bahia)</option>
                <option value="Conexão Jovem Mercado">Conexão Jovem Mercado</option>
                <option value="Indicação de amigos">Indicação de amigos</option>
                <option value="Mídias">
                  Mídias (Jornais, revistas, blogs, TVs, etc)
                </option>
                <option value="Ministério da Economia">
                  Ministério da Economia
                </option>
                <option value="Microsoft">Microsoft</option>
                <option value="SINE">SINE</option>
                <option value="Fundações">Fundações</option>
                <option value="Sindicatos">Sindicatos</option>
                <option value="Prefeitura Municipal">Prefeitura Municipal</option>
                <option value="Secretaria do Trabalho da Bahia">Secretaria do Trabalho da Bahia</option>
                <option value="Universidade Federal de Sergipe">Universidade Federal de Sergipe</option>
                <option value="Institutos Federais">Institutos Federais</option>
                <option value="Marinha do Brasil">Marinha do Brasil</option>
                <option value="Exército Brasileiro">Exército Brasileiro</option>
                <option value="Governo do Ceará">Governo do Ceará</option>
                <option value="Governo do Rio Grande do Sul">
                  Governo do Rio Grande do Sul
                </option>
                <option value="Conecta+ Microsoft">
                  Conecta+ Microsoft
                </option>
                <option value="Ministério da Defesa">
                  Ministério da Defesa
                </option>
                <option value="XP Investimentos">XP Investimentos</option>
                <option value="Força Aérea Brasileira">
                  Força Aérea Brasileira
                </option>
                <option value="Portal CATE - Prefeitura de São Paulo">
                  Portal CATE - Prefeitura de São Paulo
                </option>
                <option value="Progredir">Progredir</option>
                <option value="Organização das Cooperativas do Brasil">
                  Organização das Cooperativas do Brasil
                </option>
                <option value="Confederação Nacional dos Municípios">
                  Confederação Nacional dos Municípios
                </option>
                <option value="Brasscom">Brasscom</option>
                <option value="UFRB - Universidade Federal do Recôncavo da Bahia">
                  UFRB - Universidade Federal do Recôncavo da Bahia
                </option>
                <option value="Governo da Bahia">
                  Governo da Bahia
                </option>
                <option value="Sicomerciário Alagoinhas - BA">
                  Sicomerciário Alagoinhas - BA
                </option>
                <option value="Observatório da Inclusão e Diversidade na Educação Brasileira - Diversifica">
                  Observatório da Inclusão e Diversidade na Educação Brasileira - Diversifica
                </option>
                <option value="Prefeitura Municipal de Muribeca/SE">
                  Prefeitura Municipal de Muribeca/SE
                </option>
              </select>
              )}
              <div className="invalid-feedback">
                {comoSoube !== "Outro" && getErrorMessage("comoSoube")}
              </div>
            </div>
            
            {comoSoube === "Outro" && (
              <div className="mb-3 col-md-6">
                <input
                  type="text"
                  placeholder="Qual?"
                  className={`form-control ${
                    !isValid("comoSoube") ? "is-invalid" : ""
                  }`}
                  name="comoSoube"
                  value={form.comoSoube}
                  onChange={handleInputChange}
                  disabled={sending}
                />
                <div className="invalid-feedback">
                  {getErrorMessage("comoSoube")}
                </div>
              </div>
            )}
          </section>

          <section className="row">
            <div className="mb-3 mt-5 col-md-4">
              <a
                className="link-termo"
                role="button"
                onClick={() => setShowTermos(true)}
              >
                Visualizar termos de uso
              </a>
            </div>
            <div className="mb-5 mt-5 col-md-4">
              <div
                className={`form-check form-control ${
                  !isValid("termo") ? "is-invalid" : ""
                }`}
              >
                <input
                  required
                  className="form-check-input"
                  type="checkbox"
                  name="termo"
                  checked={form.termo}
                  onChange={handleInputChange}
                  id="termoDeUso"
                  disabled={sending}
                />
                <label className="form-check-label" htmlFor="termoDeUso">
                  Concordo com os termos de uso*
                </label>
              </div>
              <div className="invalid-feedback">{getErrorMessage("termo")}</div>
            </div>
          </section>
          <div className="mb-3 col-md-12 text-right">
            <button
              type="submit"
              className={`btn ${
                autoContraste ? "btn-outline-light" : "btn-blue"
              } ${tamanhos[tamanho].btnSize}`}
              onClick={handleSubmit}
              disabled={submitted ? !validateForm() || sending : false}
            >
              Cadastrar
            </button>
          </div>
          <div className="col-md-4"></div>
          <br />
          {showAlert && (
            <>
              <br />
              <div
                className="alert alert-warning alert-dismissible fade show"
                role="alert"
              >
                <strong>Atenção!</strong> {showAlert}
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                  onClick={() => setShowAlert("")}
                >
                  <img alt="x" src={xImage} />
                </button>
              </div>
            </>
          )}
        </form>
      </section>
    </div>
  );
}

export default Cadastro;
